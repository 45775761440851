import { TextBlock } from "@components/story-components"
import styled from "@emotion/styled"
import Box from "@ui/Box"
import { Text, Title } from "@ui/Typography"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import React, { useState } from "react"
import { FluidImg, FixedImg } from "src/types"
import { CenterBlock } from "@ui/Block"
import { color, ColorProps } from "styled-system"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import ScrollDown from "@components/ScrollDown"
import ScrollReveal from "@components/ScrollReveal"

const Img = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const Quote = styled.div`
  position: relative;
`

const Link = styled.a<{ color?: string }>`
  color: inherit;
  ${color}

  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

type Props = {
  data: {
    bg: FluidImg
    mainReport: FixedImg
    noViolence: FixedImg
    assumingTheWorst: FixedImg
    homecoming: FixedImg
    stateOfViolence: FixedImg
  }
}

const AboutPage = ({ data }: Props) => {
  return (
    <Layout showFooter>
      <SEO title="About the research" />
      <TextBlock>
        <Img fluid={data.bg.childImageSharp.fluid} />
        <Quote>
          <Title color="wheat" textAlign="center" maxWidth="53rem" as="h1">
            About
          </Title>
        </Quote>
        <ScrollDown position="absolute" bottom="3rem" my="auto" href="#intro" />
      </TextBlock>
      <CenterBlock bg="wheat" id="intro">
        <ScrollReveal>
          <Box maxWidth="60rem" width="100%">
            <Title color="cyprus" maxWidth="40ch" textAlign="center">
              Violent extremism is a leading challenge to peace across the world
            </Title>
            <Text textAlign="center" maxWidth="100ch">
              South-East Asia is home to incredibly diverse and dynamic
              communities who hold a plethora of religions, ethnicities,
              languages, and systems of governance. Those who join extremist
              groups often have non-linear motivations, and violent extremism
              remains a significant but not insurmountable challenge.
              Understanding how conflict, violence, identity, human rights and
              extremism are enmeshed offers an entry point for states to better
              understand and respond to this problem.
            </Text>
          </Box>
        </ScrollReveal>
      </CenterBlock>
      <CenterBlock bg="midnight" color="white">
        <ScrollReveal>
          <Box maxWidth="60rem" width="100%">
            <Title color="wheat" mb="2xs" textAlign="center">
              Our approach
            </Title>
            <Text
              textAlign="center"
              fontStyle="italic"
              fontSize="2xl"
              mt="2xs"
              mb="xl"
            >
              UNDP employs an inclusive, human-rights-based approach that is
              grounded in local experiences to better understand, prevent and
              mitigate violent extremism in the region.
            </Text>
            <Text textAlign="center">
              UNDP plays a prominent role when it comes to the Prevention of
              Violent Extremism (PVE). Based on the 
              <Link
                color="wheat"
                rel="nofollow noreferrer"
                href="http://www.un.org/en/ga/search/view_doc.asp?symbol=A/70/674"
              >
                Secretary-General’s Plan of Action on PVE
              </Link>
              , UNDP’s comprehensive strategic framework on{" "}
              <Link
                color="wheat"
                rel="nofollow noreferrer"
                href="https://www.undp.org/content/undp/en/home/librarypage/democratic-governance/conflict-prevention/discussion-paper---preventing-violent-extremism-through-inclusiv.html"
              >
                Preventing Violent Extremism through Inclusive Development and
                the Promotion of Tolerance and Respect for Diversity
              </Link>{" "}
              balances the need to address violent extremism with the governing
              of increasingly diverse and multicultural societies through a
              human-rights based approach.
            </Text>
          </Box>
        </ScrollReveal>
      </CenterBlock>
      <CenterBlock bg="airdust" color="white">
        <ScrollReveal>
          <Box maxWidth="60rem" width="100%">
            <Title color="wheat" textAlign="center">
              About the research
            </Title>
            <Text
              fontStyle="italic"
              fontSize="2xl"
              m="auto"
              mt="2xs"
              mb="xl"
              textAlign="center"
              maxWidth="60ch"
            >
              Entry and Exit Points: Violent Extremism in South-East Asia seeks
              to investigate factors that contribute to violent extremism in
              South-East Asia, and the actions required by states to prevent it.
            </Text>
            <Text textAlign="center">
              In partnership with the European Union, UNDP commissioned a team
              of researchers to conduct 18 months of qualitative research. This
              research is the product of 200 in-depth, in-country interviews
              with government officials, former extremists, researchers,
              journalists and those working on issues related to violent
              extremism across the region. Entry and Exit Points presents a
              summary of the findings of four thematic studies.
            </Text>
          </Box>
        </ScrollReveal>
      </CenterBlock>
      <CenterBlock bg="white" id="reports" p="" px="xl" py="5xl">
        <ReportBlock maxWidth="70rem" width="100%" py="3xl">
          <ReportLeft>
            <CoverImage
              fixed={data.mainReport.childImageSharp.fixed}
            ></CoverImage>
            <DownloadReport
              bg="midnight"
              href="/reports/UNDP_EntryandExitPointsViolentExtremisms.pdf"
              target="_blank"
            >
              Download report
            </DownloadReport>
          </ReportLeft>
          <ReportRight>
            <Title fontSize="2xl" mt="0" maxWidth="60ch">
              Entry and Exit Points: <br />
              Violent Extremism in South-East Asia
            </Title>
            <Text fontSize={["lg", "xl"]} maxWidth="80ch">
              This flagship publication provides an overview of the key findings
              of four thematic reports. It challenges assumptions about violent
              extremism and assesses PVE approaches in the South-East Asian
              context by addressing: how states can mitigate against
              environments conducive to violent extremism; the importance of
              peacemaking and conflict prevention in preventing violent
              extremism; the narratives created by different stakeholders and
              how they may incite violence; and, an analysis of the risks posed
              by both returned foreign fighters and extremists due to be
              released from prison.
            </Text>
          </ReportRight>
        </ReportBlock>
        <ReportBlock maxWidth="70rem" width="100%" py="3xl" id="no-violence">
          <ReportLeft>
            <CoverImage
              fixed={data.noViolence.childImageSharp.fixed}
            ></CoverImage>
            <DownloadReport
              bg="sunshade"
              href="/reports/UNDP_MakingPeaceandResistingViolentExtremism.pdf"
              target="_blank"
            >
              Download report
            </DownloadReport>
          </ReportLeft>
          <ReportRight>
            <Title fontSize="2xl" mt="0" maxWidth="60ch">
              "As Long As They Don't Use Violence": <br />
              Making Peace and Resisting Violent Extremism in South-East Asia
            </Title>
            <Text fontSize={["lg", "xl"]} maxWidth="80ch">
              Violence has been used to achieve political aims by governments
              and non-state actors. This paper examines the impact of past and
              ongoing conflicts, and their political responses, on Aceh, Ambon,
              Maluku, and Poso in Indonesia, Mindanao in the Philippines, and
              Timor-Leste. It explores how the conflicts interact with violent
              extremism and affect its prevention. While conflict often opens
              the door to extremists, political processes may help close it by
              creating favourable environments for disengagement, resilience and
              resistance to extremists and their ideology.
            </Text>
          </ReportRight>
        </ReportBlock>
        <ReportBlock maxWidth="70rem" width="100%" py="3xl">
          <ReportLeft>
            <CoverImage
              fixed={data.assumingTheWorst.childImageSharp.fixed}
            ></CoverImage>
            <DownloadReport
              bg="coral"
              href="/reports/UNDP_NarrativesandtheirImpactsonViolent Extremism.pdf"
              target="_blank"
            >
              Download report
            </DownloadReport>
          </ReportLeft>
          <ReportRight>
            <Title fontSize="2xl" mt="0" maxWidth="60ch">
              Assuming the Worst: <br />
              Narratives and their Impacts on Violent Extremism in South-East
              Asia
            </Title>
            <Text fontSize={["lg", "xl"]} maxWidth="80ch">
              Policy and programming for preventing or countering violent
              extremism (P/CVE) in South-East Asian states are arguably
              underpinned by assumptions about the scale, nature and sources of
              risk of violent extremism. This paper assesses the accuracy and
              utility of influential assumptions and narratives about violent
              extremism in the context of South-East Asia, its extremist groups
              and movements, and the ways that radicalization has historically
              occurred in the region. P/CVE policies need to be examined in the
              context of empirical evidence and evolving local political
              contexts if they are to avoid worsening the situation. A holistic
              research agenda would help identify overlooked sources of risk and
              better adjust resource allocation in P/CVE programming.
            </Text>
          </ReportRight>
        </ReportBlock>
        <ReportBlock
          maxWidth="70rem"
          width="100%"
          py="3xl"
          id="findings#homecoming"
        >
          <ReportLeft>
            <CoverImage
              fixed={data.homecoming.childImageSharp.fixed}
            ></CoverImage>
            <DownloadReport
              bg="cyprus"
              href="/reports/UNDP_HomecomingTheReturnofForeignTerroristFighters.pdf"
              target="_blank"
            >
              Download report
            </DownloadReport>
          </ReportLeft>
          <ReportRight>
            <Title fontSize="2xl" mt="0" maxWidth="60ch">
              Homecoming: <br />
              The Return of Foreign Terrorist Fighters in South-East Asia
            </Title>
            <Text fontSize={["lg", "xl"]} maxWidth="80ch">
              South-East Asians who travelled to fight and live in Iraq and
              Syria are beginning to return home. Returning foreign terrorist
              fighters (FTFs) may pose a risk to peace in South-East Asia unless
              governments design effective responses. This paper analyses the
              real risks posed by those that return, both combatants and
              non-combatants, and explores the issues faced by governments and
              local communities as well as FTFs. The reasons that lead people to
              return home are as diverse as the decisions to leave. Even if only
              small numbers come home to Indonesia and Malaysia, individualized
              reintegration programmes are urgently needed. Disengagement from
              violence— rather than deradicalization - should be the focus of
              government efforts.
            </Text>
          </ReportRight>
        </ReportBlock>
        <ReportBlock
          maxWidth="70rem"
          width="100%"
          py="3xl"
          id="state-of-violence"
        >
          <ReportLeft>
            <CoverImage
              fixed={data.stateOfViolence.childImageSharp.fixed}
            ></CoverImage>
            <DownloadReport
              bg="midnight"
              href="/reports/UNDP_GovernmentResponsestoViolentExtremism.pdf"
              target="_blank"
            >
              Download report
            </DownloadReport>
          </ReportLeft>
          <ReportRight>
            <Title fontSize="2xl" mt="0" maxWidth="60ch">
              State of Violence: <br />
              Government responses to violent extremism in South-East Asia
            </Title>
            <Text fontSize={["lg", "xl"]} maxWidth="80ch">
              States have a sizable role to play in the emergence or expansion
              of violent extremism through policies that have marginalized
              ethnic or religious groups. This paper argues that heavily
              securitized state responses to violent extremism, exclusionary
              politics based on religious and ethnic identity, state action and
              inaction which reinforces hate speech and intolerance in society,
              and the use of violence against citizens are all ways in which
              governments may engender further violence. Instead, South-East
              Asian governments should implement policies and programmes that
              center around upholding human rights, building inclusive politics
              through conflict resolution, protecting minority rights, reducing
              violence and tackling hate speech.
            </Text>
          </ReportRight>
        </ReportBlock>
      </CenterBlock>
      <AcknowledgementsBlock>
        <Box maxWidth="60rem" width="100%">
          <Title>Acknowledgments</Title>
          <AuthorCategory>Researchers</AuthorCategory>
          <Author name="Ben Schonveld">
            Ben Schonveld is a conflict, human rights, PVE and rule of law
            expert. He has over 20 years of experience in research, policy,
            programme and evaluation work. He has served in senior capacities
            for the UK FCO, the United Nations, European Union and GIZ as well
            as a wide range of International NGOs including most recently, as
            the South Asia Director for the International Commission of Jurists.
          </Author>
          <Author name="Robert Templer">
            Robert Templer is an author, analyst and educator who has spent 20
            years working on conflict issues across Asia. He is a former Asia
            Director of the International Crisis Group and professor at the
            School of Public Policy at the Central European University in
            Budapest.
          </Author>
          <Author name="Jim Della-Giacoma">
            For more than 25 years, Jim Della-Giacoma has been reporting on and
            researching about conflict, insurgencies and violent extremism in
            Asia. As the former South-East Asia Project Director of the
            International Crisis Group, between 2009 and 2013 he oversaw the
            organisations’ analysis of terrorism, violent radical networks and
            ethno–religious conflicts in the region. He has also advised a wide
            range of United Nations and other international institutions on
            understanding regional conflicts and preventing violent extremism.
          </Author>
          <Author name="Ahmad El-Muhammady">
            Dr. Ahmad El-Muhammady is a counter-terrorism analyst at the
            Extremism Analytical Research Unit, International Institute of
            Islamic Thought and Civilization (ISTAC-IIUM), Malaysia. He is also
            a consultant to the Special Branch (Counter-Terrorism Division,
            Royal Malaysia Police) and has testified in Kuala Lumpur High Court
            as an expert witness in terrorism-related cases. He is part of a
            rehabilitation team appointed by the Ministry of Home Affairs to
            rehabilitate militants detained in Malaysia.
          </Author>
          <AuthorCategory>Multimedia Journalists</AuthorCategory>
          <Author name="Alecs Ongcal">
            Alecs Ongcal is a Manila-based freelance photojournalist who has
            covered breaking stories in the Philippines since 2013. Alecs has
            contributed her works to various international wire agencies and
            local news outlets that include the Associated Press, the European
            Press Agency, the National Press Radio, and rappler.com. She has
            also worked for UNHCR, ICRC, EDUCO, NILAB, ABS-CBN News Online, and
            GMA News.
          </Author>
          <Author name="Jules Ong">
            Jules Ong is a freelance journalist, news producer and award-winning
            documentary filmmaker based in Malaysia with a special interest in
            making films about the environment, civil society, and marginalised
            communities. Jules has extensive experience in producing and
            conducting investigative work. He is also the co-founder of Rainbow
            of Love School for Refugee Children, an exco member of the Centre of
            Malaysian Indigenous Studies, and co-founder of Bumi Kasih
            Permaculture.
          </Author>
          <Author name="Jefri Tarigan">
            Jefri Tarigan is a freelance documentary photographer who has been
            covering current affairs since 2007. He is based in Indonesia and
            has worked with the likes of BBC, ABC TV, Sydney Morning Herald,
            Australia Finance Today, The Australian, The Straits Times, Barcroft
            Media, and others.
          </Author>
          <Author name="Fakhrur Rozi ">
            Fakhrur Rozi is a Freelance Videographer & Director based in
            Indonesia. He has worked for Reuters TV, National Geographic,
            UNICEF, and Al Gore Climate Change USA. He has also created
            documentaries for Al Jazeera TV, CGTN China, Channel News Asia, and
            Swiss Television (SF).
          </Author>
          <Author name="Lawrence Nel">
            Lawrence Nel is a Bangkok based freelance videographer, video editor
            and documentary filmmaker. He has created content highlighting
            social development issues and messaging over the past 10 years
            throughout Asia and the Pacific on projects for UNESCAP, UNODC,
            UNDP, UN Environment, IOM, IFRC, Google and Facebook.
          </Author>
        </Box>
      </AcknowledgementsBlock>
    </Layout>
  )
}

const AcknowledgementsBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.viridian};
  color: #fff;
  width: 100%;
  padding: ${({ theme }) => `${theme.space["5xl"]} ${theme.space["xl"]}`};
`

const AuthorCategory = styled.h3`
  margin-top: ${({ theme }) => theme.space["4xl"]};
  margin-bottom: 0;
  font-weight: 600;
  font-size: ${({ theme }) => theme.space.md};
  color: ${({ theme }) => theme.colors.wheat};
`

const AuthorName = styled.button`
  font-weight: 600;
  padding: ${({ theme }) => `${theme.space["2xs"]} 0`};
  cursor: pointer;
  line-height: 1.5;

  appearance: none;
  background: none;
  border: 0;
  transition: opacity 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
  color: inherit;
  display: block;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  &[aria-expanded="false"]:before {
    content: "+ ";
  }

  &[aria-expanded="true"]:before {
    content: "− ";
  }
`

const Bio = styled.div`
  padding-bottom: ${({ theme }) => theme.space.md};
`

const Author = ({ name, children }: any) => {
  const [isExpanded, setExpanded] = useState<boolean>(false)
  return (
    <>
      <AuthorName
        aria-expanded={isExpanded}
        onClick={() => setExpanded(prev => !prev)}
      >
        {name}
      </AuthorName>
      <Bio hidden={!isExpanded}>{children}</Bio>
    </>
  )
}

const ReportBlock = styled(Box)`
  display: flex;

  flex-direction: column;

  @media screen and (min-width: 60rem) {
    flex-direction: row;
  }
`

const ReportLeft = styled.div`
  max-width: 21rem;
  margin-bottom: ${({ theme }) => theme.space["2xl"]};

  @media screen and (min-width: 60rem) {
    margin-right: ${({ theme }) => theme.space["5xl"]};
  }
`

const ReportRight = styled.div``

const CoverImage = styled(Image)`
  max-width: 100%;
`

const DownloadReport = styled.a<ColorProps & { color?: any }>`
  ${color};
  display: block;
  white-space: nowrap;
  text-align: center;
  padding: ${({ theme }) => `${theme.sizes.sm} ${theme.sizes.lg}`};
  font-family: ${({ theme }) => theme.fonts.effect};
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.25;
  color: #fff;
  cursor: pointer;
  margin: ${({ theme }) => theme.space["md"]} 0;

  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export const pageQuery = graphql`
  query {
    bg: file(relativePath: { eq: "about_bg.jpg" }) {
      ...BlockImage
    }
    mainReport: file(
      relativePath: { eq: "findings/covers/entry_exit_points.jpg" }
    ) {
      childImageSharp {
        fixed(fit: COVER, width: 340, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    noViolence: file(relativePath: { eq: "findings/covers/no_violence.jpg" }) {
      childImageSharp {
        fixed(fit: COVER, width: 340, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    assumingTheWorst: file(
      relativePath: { eq: "findings/covers/assuming_the_worst.jpg" }
    ) {
      childImageSharp {
        fixed(fit: COVER, width: 340, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    homecoming: file(relativePath: { eq: "findings/covers/homecoming.jpg" }) {
      childImageSharp {
        fixed(fit: COVER, width: 340, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    stateOfViolence: file(
      relativePath: { eq: "findings/covers/state_of_violence.jpg" }
    ) {
      childImageSharp {
        fixed(fit: COVER, width: 340, cropFocus: ATTENTION, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default AboutPage
