import styled from "@emotion/styled"
import React, { useEffect, useRef } from "react"

import { useScrollController } from "./scroll-controller"

export const Reveal = styled.div`
  opacity: 0;

  transition: opacity 500ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &.visible {
    opacity: 1;
  }
`

type Props = {
  children: React.ReactNode
}

const ScrollReveal = ({ children, ...props }: Props) => {
  const trigger = useRef<any>()

  const { ScrollMagic, controller } = useScrollController()

  useEffect(() => {
    if (ScrollMagic && controller) {
      // build scene
      new ScrollMagic.Scene({
        triggerElement: trigger.current,
        triggerHook: 0.6,
        offset: 0, // move trigger to center of element
        reverse: false, // only do once
      })
        .setClassToggle(trigger.current, "visible") // add class toggle
        .addTo(controller)
    }
  }, [ScrollMagic, controller])

  return (
    <Reveal {...props} ref={trigger}>
      {children}
    </Reveal>
  )
}

export default ScrollReveal
