import styled from "@emotion/styled"
import { Block } from "@components/story-components"
import { PositionProps, position } from "styled-system"

export const CenterBlock = styled(Block)<PositionProps>`
  ${position};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

CenterBlock.defaultProps = {
  p: "xl",
}
