import styled from "@emotion/styled"

import {
  LayoutProps,
  color,
  ColorProps,
  layout,
  space,
  SpaceProps,
  position,
  PositionProps,
} from "styled-system"

const Box = styled.div<
  PositionProps &
    SpaceProps &
    LayoutProps &
    ColorProps & { as?: any; color?: string }
>`
  ${layout};
  ${position};
  ${space};
  ${color};
`

export default Box
