import React from "react"
import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import {
  SpaceProps,
  layout,
  space,
  LayoutProps,
  position,
  PositionProps,
} from "styled-system"

const scrollDownAnimation = keyframes`
  0% {
      transform: rotate(-45deg) translate(0, 0);
  }
  20% {
      transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
      transform: rotate(-45deg) translate(0, 0);
  }
`

const ScrollDownLink = styled.a`
  position: relative;
  z-index: 2;
  display: block;
  transform: translate(0, -50%);
  color: ${({ theme }) => theme.colors.wheat};
  text-decoration: none;
  transition: opacity 0.3s;
  width: 48px;
  height: 48px;

  ${layout};
  ${space};
  ${position};

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    transform: rotate(-45deg);
    animation: ${scrollDownAnimation} 2s infinite;
    box-sizing: border-box;
  }

  &:hover,
  &:focus {
    outline: 0;
    opacity: 0.6;
  }
`

type ScrollDownProps = React.PropsWithRef<JSX.IntrinsicElements["a"]> &
  LayoutProps &
  PositionProps &
  SpaceProps & {
    href: string
  }

const ScrollDown = (props: ScrollDownProps) => {
  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()

    const element = document.body.querySelector(props.href)

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      })
    }
  }
  return (
    <ScrollDownLink aria-label="scroll down" {...props} onClick={onLinkClick}>
      <span />
    </ScrollDownLink>
  )
}

export default ScrollDown
